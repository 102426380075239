import { Link } from "react-router-dom"

export default function PageError() {
	return (
		<section className="flex flex-col justify-center h-screen">
			<div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
				<div className="mx-auto max-w-screen-sm text-center">
					<img className="w-64 m-auto" src={'/images/404-computer.svg'} />
					<p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white">Something's missing.</p>
					<p className="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">Sorry, we can't find that page. You'll find lots to explore on the home page. </p>
					<Link to={"/"} className="inline-flex text-white hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-900 my-4 bg-[#771D1D]">Back to Homepage</Link>
				</div>   
			</div>
		</section>
	)
}
