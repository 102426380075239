import { ReactNode, useEffect,useState,useMemo, useCallback } from "react"
import { Navigate, useLocation, useNavigate, useRevalidator } from "react-router-dom"
import { useCookies } from "../store/cookies"



export default function ProtectedRoute({children}:{children:ReactNode})
{
	 const {cookies} = useCookies('lgn')
	 const location = useLocation()

	 	 
	 const isLogged = () =>{
	 	if (cookies.value) {	

	 		// console.log(cookies.decode)
	 		return true
	 	}


	 	// setLocation(location.pathname)
	 	return false
	 	
	 }

	

	

	 return (
	 	<>
	 		{ isLogged() ? children : <Navigate to={'/login'} replace/>}
	 	</>
	 	)

}