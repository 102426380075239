import { useQuery } from "@tanstack/react-query";

import { PaginationState } from "@tanstack/react-table";
import { useCallback } from "react";

// import { usePortalStore } from "../store/portal";

const API_URL = `${process.env.REACT_APP_KONTENBASE_API_URL}/${process.env.REACT_APP_KONTENBASE_API_KEY}`

const useQueryUser = () => {
    // const updateUser = usePortalStore((state)=>state.updateUser)
    // const zUser = usePortalStore((state)=>state.user)
    return useQuery({
        queryKey: ["user"],
        queryFn: () =>
            fetch(
                `/api/v1/users/settings/identity`
            ).then((res) => res.json()),
        // onSuccess: (data) => {
        //     updateUser(data)
        //     // console.log({ zUser })
        // },
    });
};

const useQueryWebsite = () => {
    // const updateWebsite = usePortalStore((state)=>state.updateWebsite)
    // const zWebsite =  usePortalStore((state)=>state.website)
    return useQuery({
        queryKey: ["website"],
        queryFn: () =>
            fetch(
                `/api/v1/services/analytics/websites`
            ).then((res) => res.json()),
        // onSuccess: (data) => {
        //     updateWebsite(data)
        //     // console.log({ zWebsite})
        // }
    });
};

const useQueryLicenses = () => {
    return useQuery({
        queryKey: ["licenses"] ,
        queryFn: () =>
            fetch(
                `/api/v1/services/licenses`
            ).then((res) => res.json()),
    });
};




const useQueryWallets = () => {
    return useQuery({
        queryKey: ['wallets'] ,    
        queryFn: () => fetch(`${API_URL}/wallets`).then(res => res.json()),     
        select: useCallback(
            (data: { data: TWallets[] }): { data: TWallets[] } => {
                const saldo = {
                    data: data.data
                        .slice(0)
                        .reverse()
                        .map((value, index, array) => {
                            if (value.status === "Completed") {
                                index === 0
                                    ? (value.saldo = value.nominal)
                                    : (value.saldo =
                                          array[index - 1].saldo +
                                          array[index].nominal);
                            } else if (value.status === "Pending") {
                                index === 0
                                    ? (value.saldo = 0)
                                    : (value.saldo = array[index - 1].saldo);
                            }
                            return value;
                        })
                        .reverse(),
                };

                return saldo;
            },
            []
        ),
    });
};

export { useQueryUser, useQueryWebsite, useQueryLicenses, useQueryWallets };

export type TWallets = {
    ID: number;
    nominal: number;
    transaction: string;
    status: string;
    saldo: number;
    createdAt: string;
};

export function formatCurrency(cur: number) {
    return `IDR ${new Intl.NumberFormat("id-ID").format(cur)}`;
}

//wallets json test

const walletsJSON = {
    data: [
        {
            ID: 24,
            nominal: 250007,
            transaction: "Topup Saldo",
            status: "Pending",
            createdAt: "2023-04-12T08:49:26.600Z",
        },
        {
            ID: 22,
            nominal: 100000,
            transaction: "Topup Saldo",
            status: "Pending",
            createdAt: "2023-04-11T10:49:26.600Z",
        },
        {
            ID: 412,
            nominal: -200000,
            transaction: "Renewal-LWDonasi Pro",
            status: "Completed",
            createdAt: "2023-04-10T11:49:26.600Z",
        },
        {
            ID: 122,
            nominal: 500000,
            transaction: "Topup Saldo",
            status: "Completed",
            createdAt: "2023-04-10T09:49:26.600Z",
        },
    ],
};
