import { useMemo, useState, useEffect } from "react"
import { useQueryWallets, TWallets } from "../../database/query"
import {
  PaginationState,
  getFilteredRowModel,
  getPaginationRowModel,
  getCoreRowModel,
  useReactTable,
  createColumnHelper,
  flexRender
} from "@tanstack/react-table"

import Filter from "./input/filter"
import { usePortalStore } from "../../store/portal"

// import { saldoTopup } from "../../store/portal"




function Table() {
  const setSaldotopup = usePortalStore((state)=>state.updateSaldoTopup)
  const { data } = useQueryWallets()
  
  const images = {
    plus:'/images/plus.svg',
    minus:'/images/minus.svg',
    plusGreen:'/images/plus-green.svg'
  }
  // const [saldotopup,setSaldotopup] = useAtom(saldoTopup)

  useEffect(()=>{
    console.log({data})
    setSaldotopup(Number(data?.data[0].saldo))
  },[data])

  const columnHelper = createColumnHelper<TWallets>()
  const columns = [
    columnHelper.accessor('ID', {
      cell: info => "# " + info.getValue(),
      footer: info => info.column.id,
    }),
    columnHelper.accessor('nominal', {
      cell: info => {
        let currentValue = info.getValue()
        let completed = info.row.original.status === 'Completed' 
        let formattedValue = <p>{`IDR ${new Intl.NumberFormat('id-ID').format(currentValue)}`}</p>

        

        if (typeof currentValue === 'number') {
          if (currentValue > 0) formattedValue = <div className="flex gap-2">{completed ? <img src={images.plusGreen} /> : <img src={images.plus} /> } {`IDR +${new Intl.NumberFormat('id-ID').format(currentValue)}`}</div>

          if (currentValue < 0) formattedValue = <div className="flex gap-2"><img src={images.minus} />{`IDR ${new Intl.NumberFormat('id-ID').format(currentValue)}`}</div>
        } 
           

        return formattedValue
        
      },
      header: () => <span>Nominal</span>,
      footer: info => info.column.id,
    }),
    columnHelper.accessor('transaction', {
      cell: info => info.getValue(),
      header: () => <span>Transaction</span>,
      footer: info => info.column.id,
    }),
    columnHelper.accessor('createdAt', {
      cell: info => info.getValue(),
      header: () => <span>Tanggal</span>,
      footer: info => info.column.id,
    }),
    columnHelper.accessor('saldo', {
      cell: info => { 
        let currentValue = info.getValue()
        let formattedValue = <p>{`IDR ${new Intl.NumberFormat('id-ID').format(currentValue)}`}</p>

        if (typeof currentValue === 'number') {
          formattedValue = <div className="flex gap-2">{`IDR ${new Intl.NumberFormat('id-ID').format(currentValue)}`}</div>
        }
        return formattedValue
        
    },
      header: () => <span>Saldo</span>,
      footer: info => info.column.id,
    }),
    columnHelper.accessor('status', {
      cell: info => {
        if (info.getValue() === 'Completed') return <><div className="flex gap-2"><svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="5" cy="10" r="3" fill="#0BB07B" />
        </svg> {info.getValue()} </div></>

        return <><div className="flex gap-2"><svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="5" cy="10" r="3" fill="white" />
        </svg> {info.getValue()} </div></>
      },
      header: () => <span>Status</span>,
      footer: info => info.column.id,
    }),

   
  ]

  const defaultData = useMemo(() => [], [])

  const table = useReactTable({
    data: data?.data ?? defaultData,
    columns,
    // Pipeline
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    //
    // debugTable: true,
  })

  
  const transactionColumn = table?.getColumn('transaction')
  const statusColumn = table?.getColumn('status')
  const [statusVal, setStatusVal] = useState<string>('All')
  const handleFilterTransaction = (value: string | number) => {
    transactionColumn?.setFilterValue(value)
    
  }

  useEffect(() => {
    // table && console.log(table.getColumn('transaction'))
    // console.log()
    // data && console.log(data)
  }, [data])

  return (
    <>
    

      <div className="flex justify-between w-full justify-between items-center mb-2">
        <div className="">
          <label htmlFor="simple-search" className="sr-only">Search Transaction</label>
          <div className="relative w-full">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
              </svg>
            </div>

            <Filter filterFunc={handleFilterTransaction} />
          
          </div>

          

        </div>
        
        <select className="text-sm py-2 px-3 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-[#313131] dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white rounded-lg" 
          value={statusVal}
          onChange={e => {
            if (e.target.value === 'All') {
              statusColumn?.setFilterValue('') 
            } else {
              statusColumn?.setFilterValue(e.target.value)
            }
            setStatusVal(e.target.value)
          }}>
          {['All', 'Completed', 'Pending'].map(item => (
            <option key={item} value={item} className="text-sm">{item}</option>
          ))}
  
  
        </select>

      </div>

          
          


      <div className="overflow-x-auto">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-[#313131] dark:text-gray-400 ">
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <th key={header.id} colSpan={header.colSpan} scope="col" className="px-4 py-3 rounded-head">
                      {header.isPlaceholder ? null : (
                        <div>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </div>
                      )}
                    </th>
                  )
                })}
              </tr>
            ))}
               
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => (
              <tr key={row.id}>
                {row.getVisibleCells().map(cell => (
                  <td key={cell.id} className="px-4 py-2 whitespace-nowrap dark:text-white-gray">
                        
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        
                  </td>
                ))}
              </tr>
            ))}

             

          </tbody>
        </table>
      </div>


      <nav className="mt-4 flex justify-between">

        <div className="flex items-center gap-4 dark:text-white-gray text-sm">
          <select className="text-sm py-2 px-3 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-[#313131] dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white rounded-lg" 
            value={table.getState().pagination.pageSize}
            onChange={e => {
              table.setPageSize(Number(e.target.value))
            }}>
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize} className="text-sm">{pageSize}</option>
            ))}
  
  
          </select>
          <span>row per page</span>
          <span>1-{table.getState().pagination.pageSize} of {table.getCoreRowModel().rows.length}</span>
        </div>

        <div className="flex gap-2 items-center dark:text-white-gray text-sm">
          <div className="flex items-center justify-center text-sm py-2 px-3 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-[#313131] dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white rounded-lg text-sm">{table.getState().pagination.pageIndex + 1}</div>
          <span>of {table.getPageCount()} page</span>
          <ul className="inline-flex items-stretch -space-x-px gap-2">
            <li>
              <button className="flex items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-[#313131] dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white text-sm" onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}>
                <span className="sr-only">Previous</span>
                <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                </svg>
              </button>
            </li>
                   
            <li>
              <button className="text-sm flex items-center justify-center h-full py-1.5 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-[#313131] dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white" onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}>
                <span className="sr-only">Next</span>
                <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                </svg>
              </button>
            </li>
          </ul>
        </div>
          
      </nav>
  

      
    </>
  ) 
} 

export default Table