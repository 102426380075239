import Cookies from "js-cookie"

type TCookies = {
	value: string | undefined,
	decode: object
}

const useCookies = (key:string) =>{
	
	const cookies: TCookies = { 
						value:Cookies.get(key),
						decode:Cookies.get(key) ? base64.decode(Cookies.get(key) as string) : {}
					}
	const setCookies = (key_:string,value:string) =>{
			Cookies.set(key_,value,{expires:1})
	}
	const removeCookies = (key_:string) => {
			Cookies.remove(key_)
	}

	

	return { cookies, setCookies, removeCookies }

} 

const base64 = {
	encode(obj:object){
		return btoa(JSON.stringify(obj))
	},
	decode(value:string){
		return JSON.parse(atob(value))
	}
}

export { useCookies,base64 }