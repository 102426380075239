import { TokenResponse, useGoogleLogin } from "@react-oauth/google";
// import {  lastLocation } from "../store/portal";
import { useLocation, useNavigate } from "react-router-dom";
import { base64, useCookies } from "../store/cookies";
import { useQuery } from "@tanstack/react-query";

export function useLoginWithGoogle() {
	
	// const [lastloc] = useAtom(lastLocation)

	const navigate = useNavigate();
	const { setCookies, removeCookies} = useCookies('lgn')

	const LoginOnSuccess = async (response: TokenResponse) => {
		const userProfile = await (
			await fetch(
				`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${response.access_token}`,
				{
					headers: {
						"Content-Type": "appplication/json",
					},
				}
			)
		).json();

		// console.log(profile)
		if (userProfile) {
			let jwt = base64.encode(userProfile);
			
			setCookies('lgn',jwt);

			
			navigate('/')
		} else {
			removeCookies('lgn')
		}
	};

	const LoginOnError = (error: any) => {
		
		console.log(error);
	};

	return useGoogleLogin({
		onSuccess: LoginOnSuccess,
		onError: LoginOnError,
	});
}

export function useQueryProfile() {
	const {cookies} = useCookies("lgn");
	

	return useQuery({
		queryKey:['profile-cookie'],
		queryFn:()=>{
			return cookies.decode
		}
	})
		
	
}
