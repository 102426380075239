// import { PortalAppProvider } from "./store/portal"
// import Login from "./pages/login"
// import { DarkThemeToggle, Flowbite } from "flowbite-react"
// import ErrorPage from "./pages/error"
// import { useState, useEffect } from "react"

import Sidebar from "./components/sidebar"
import { Outlet } from "react-router-dom"
import { getKontenbaseClient } from "./database/client"

export default function App() {
  // const [dev] = useState(true) 
 const client = getKontenbaseClient()
 

  return (
    <>
      
     
        <Sidebar />
        <div className="sm:ml-[290px]">
          <Outlet/>
        </div>
      
     

     
    
    </>
  )
}

