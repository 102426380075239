import {
  useState,
  MouseEvent,
  useEffect
} from "react";

import { Button, Modal } from "flowbite-react";


import { formatCurrency } from "../../database/query";
import { usePortalStore } from "../../store/portal";


export type TTopup = {
  show:boolean,
  handleTopup:()=>void,
  nominal:number
}

export default function Topup({show,handleTopup,nominal}:TTopup) {
  // const show = useTopupModal((state) => state.show);
  // const nominal = useTopupModal((state) => state.nominal);
  // const updateShow = useTopupModal((state) => state.updateShow);
  // const updateNominal = useTopupModal((state) => state.updateNominal);

  const [fee,setFee] = useState<number>(5000)
  const [pajak,setPajak] = useState<number>(0)
  const [total,setTotal] = useState<number>(0)
  const saldoTopups = usePortalStore((state) => state.saldoTopup)
 

  useEffect(()=>{
     setPajak(nominal*0.11)
     setTotal(nominal+fee+pajak)
     
  },[nominal,show,pajak])

  

  
  return (
    <>
      <Modal show={show} size="md">
        <Modal.Body className="bg-[#292929] text-[#DDDDDD] rounded-lg">
          
            <div className="flex mb-4 justify-between">
              <div className="flex gap-2">
                <img src={'/images/credits.svg'} />
                <div className="text-[#DDDDDD]">Credit</div>
              </div>
            </div>
            <div>Nominal</div>

            <div className="py-4">
              <input
                type="text"
                id="input-nominal"
                
                value={formatCurrency(nominal)}
                className="w-full bg-[#292929] border-opacity-50 border-[#5F5F5F] rounded p-4 text-right text-3xl font-medium"
                onChange={()=>{}}
              />
            </div>

            <div className="p-2">
              <div className="flex justify-between">
                <div>Pajak 11%</div>
                <div>{formatCurrency(pajak)}</div>
              </div>

              <div className="flex justify-between">
                <div>Fee Pembayaran</div>
                <div>{formatCurrency(fee)}</div>
              </div>

              <hr className="my-4 border-opacity-50 border-[#5f5f5f]" />

              <div className="flex justify-between">
                <div>Total</div>
                <div className="font-medium">{formatCurrency(total)}</div>
              </div>

              <div>
                <button
                  onClick={handleTopup}
                  type="submit"
                  className="mt-4 py-3 px-5 text-sm font-medium text-center text-white rounded bg-primary-700 w-full hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-[#771d1d] dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                >
                  Bayar
                </button>
              </div>
            </div>
          
        </Modal.Body>
      </Modal>
    </>
  );
}
