import { useEffect, useState} from 'react'
import { useLoginWithGoogle } from '../auth/google'




export default function Login() {
	
	const authWithGoogle = useLoginWithGoogle()


	return (

		<section className="bg-white dark:bg-[#1a1a1a]">
			<div className="py-8 px-4 mx-auto my-auto justify-center items-center space-y-4 w-screen h-screen text-center lg:py-16 flex flex-col">

				<img src={'/images/logo-white.svg'} />
				<h4 className='dark:text-white'>Log in to Portal</h4>
				{/*<form className="flex flex-col gap-4" onSubmit={emailSubmit}>*/}
					{/*<div>
						
					<input type="email" id="email" className="dark:bg-[#2e2e2e] dark:text-[#787878] border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-none dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 w-[300px]" placeholder="input email ex. email@gmail.com" required/>

					</div>

					<button type="submit" className="py-2.5 px-5 mr-0 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-none dark:hover:text-white dark:hover:bg-gray-700 w-[300px] dark:bg-[#404040] dark:text-[#DDDDDD]" onClick={()=>{setWhich('email')}}>Continue with Email</button>*/}
					
					<button type="button" className="py-2.5 px-5 mr-0 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-none dark:hover:text-white dark:hover:bg-green-600 w-[300px] dark:bg-[#771D1D] dark:text-[#DDDDDD]" onClick={()=>authWithGoogle()}>Continue with Google <img className="ml-2 inline-block w-6" src="/google-logo.png"/></button>
					
				{/*</form>*/}

			</div>
		</section>

	)
}