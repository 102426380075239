export default function Settings() {
  return (
    <>
      <div className="h-full w-full dark:bg-[#1a1a1a]">
        <div className="dark:text-white text-lg m-4">
          <img src={'/images/pengaturan.svg'} className='inline ml-2 mr-2' />
          <div className="inline align-middle text-[#DDDDDD]">Pengaturan</div>
        </div>

        <div className="md:flex">

          <div className="flex justify-between ml-4 bg-[#414141] text-[#dddddd] rounded  px-6 py-2 w-[250px] h-10">
            Account
            <img src={'/images/check.svg'} />
          </div>
                
          <div className="ml-4 py-8 px-4 mx-auto md:min-w-[500px] bg-[#292929] rounded-xl px-8 mr-4 mt-4 md:mt-0">
            <p className="tracking-tight font-bold text-gray-900 dark:text-white">Data Pribadi</p>
            <p className="mb-8 font-light text-gray-500 dark:text-gray-400 ">Ubah dan atur data pribadi kamu disini</p>
            <form action="#" className="space-y-8">
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Nama</label>
                <input type="text" id="nama" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-[#2e2e2e] dark:border-[#2d2d2d] dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="nama kamu" required />
              </div>
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Phone</label>
                <input type="text" pattern="[0-9]{8,12}" id="phone" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-[#2e2e2e] dark:border-[#2d2d2d] dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="phone kamu" required />
              </div>
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Email</label>
                <input type="email" id="email" className="bshadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-[#2e2e2e] dark:border-[#2d2d2d] dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="email kamu" required />
              </div>
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">NPWP</label>
                <input type="text" id="npwp" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-[#2e2e2e] dark:border-[#2d2d2d] dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="npwp kamu" required />
              </div>
              
              <div className="text-right">
              <button type="submit" className="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-primary-700 sm:w-fit hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-[#771d1d] dark:hover:bg-primary-700 dark:focus:ring-primary-800">Simpan</button>
              </div>

            </form>
          </div>

        </div>


               
      </div>
    </>
  )
} 