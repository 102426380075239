import { useNavigate, useLocation, Link } from 'react-router-dom'
import { useState,useRef, useEffect } from 'react'
// import { useAtom } from 'jotai'
// import {  saldoTopup } from '../store/portal'
import { formatCurrency,useQueryWallets } from '../database/query'
import { useQueryProfile } from '../auth/google'
import { useCookies } from '../store/cookies'
import { usePortalStore } from '../store/portal'


export default function Sidebar() {
  const navigate = useNavigate()
  const location = useLocation()
  const {data:profile}:any = useQueryProfile()
  const saldoTopup = usePortalStore((state)=>state.saldoTopup)
  const setSaldotopup = usePortalStore((state)=>state.updateSaldoTopup)
  const { data } = useQueryWallets()


  useEffect(()=>{
     setSaldotopup(Number(data?.data[0].saldo))
  },[data])

  const OnSettingHandler = () => {
    
    navigate('/setting')
  }

  const onHomeHandler = () => {
    navigate('/')
  
  }

  const checkLocation = (loc:string) => {
    return loc === location.pathname
  }



  
  return (
    <>
      <button data-drawer-target="default-sidebar" data-drawer-toggle="default-sidebar" aria-controls="default-sidebar" type="button" className="inline-flex items-center p-2 mt-2 ml-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
        <span className="sr-only">Open sidebar</span>
        <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path clipRule="evenodd" fillRule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
        </svg>
      </button>

      <aside id="default-sidebar" className="fixed top-0 left-0 z-40 w-[289px] h-screen transition-transform -translate-x-full sm:translate-x-0" aria-label="Sidenav">
        <div className="overflow-y-auto py-5 px-3 h-full bg-white border-r border-gray-200 dark:bg-black-lokuswp dark:border-gray-700">


          {/* Lokuswp top*/}
          <div className="flex justify-between">
            <div role='button' className='flex' onClick={onHomeHandler}>
              <img src={'/images/logo-white.svg'} className='w-8' />
              <h1 className='dark:text-white ml-4 font-semibold'>LokusWP</h1>
            </div>
            <img src={'/images/bell.svg'} className="w-4"  />
          </div>

          {/* Lokuswp client*/}
          <div className="flex justify-between mt-4 mb-4">
            <div className='flex flex-col'>
              <h1 className='dark:text-white text-sm font-semibold'>{profile?.name}</h1>
              <h1 className='dark:text-white text-xs'>{profile?.email}</h1>
            </div>
            <img className='w-8 h-8' src={profile?.picture} />
          </div>

          {/* Lokuswp menu */}

          <ul className="space-y-2">
            <li>
              <Link to={"/message"} className={`flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group ${checkLocation('/message') ? 'bg-[#771D1D]': ''}`}>
                <img src={'/images/messages.svg'} />
                <span className="flex-1 ml-3 whitespace-nowrap">Messages</span>
                <span className="inline-flex justify-center items-center w-5 h-5 text-xs font-semibold rounded-full text-primary-800 bg-primary-100 dark:text-white dark:rounded-full dark:bg-[#333333] p-2">
                  6
                </span>
              </Link>
            </li>

            <li>
              <Link to={'/license'} className={`flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group ${checkLocation('/license') ? 'bg-[#771D1D]': ''}`}>

                <img src={'/images/licenses.svg'}  />
                <span className="flex-1 ml-3 whitespace-nowrap">Licenses</span>
                <span className="inline-flex justify-center items-center w-5 h-5 text-xs font-semibold  text-primary-800 bg-primary-100 dark:text-white  dark:bg-[#333333] pt-2 pb-4 pl-2 pr-4 rounded-full" style={{ 'padding': '10px 15px' }}>
                  200
                </span>
              </Link>
            </li>
          </ul>

          {/* Lokuswp setting*/}
          <div className='absolute bottom-0 mb-[10px] w-[90%] flex flex-col'>
            <div className='flex py-1 justify-center items-center'>
              <img src={'/images/idr.svg'} className='ml-2' />
              <div className='flex justify-between w-full py-1 items-center'>
                <h3 className='ml-2 text-white'>{formatCurrency(saldoTopup)}</h3>
                <Link to={"/wallet"} className='button text-[#DDDDDD] bg-[#771D1D] rounded px-4 py-1' role="button">Topup</Link>
              </div>
            </div>


            <div role='button' className='text-white text-[#DDDDDD] rounded-lg pr-4 py-1 mt-1' onClick={OnSettingHandler} style={checkLocation('/setting') ? {backgroundColor: '#771D1D'}:{}}>

              <img src={'/images/pengaturan.svg'} className='inline ml-2 mr-2' />

              <span>Pengaturan </span>
            </div>



          </div>




        </div>




      </aside>

      
    </>

  )
}