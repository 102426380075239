import { useContext, useReducer, useRef, createContext, ReactElement, useState, useEffect } from "react"
import { create } from "zustand"

type ChildrenType = { children?: ReactElement | ReactElement[] }

// type InitPortalAppContextType = {
//     state:string 

// }

// const InitPortalAppContext = {
//     state: ''

// }


// const PortalAppContext = createContext<InitPortalAppContextType>(InitPortalAppContext)

// export const PortalAppProvider = ({ children }: ChildrenType): ReactElement => {
//     const data = ''
//     return (
//         <PortalAppContext.Provider value={data}>
//             {children}
//         </PortalAppContext.Provider>
//     )
// }

// export const usePortalAppContext = () => {
//     const context = useContext(PortalAppContext)
//     return { ...context }


// }

export type TPortalStore = {
    user:{},
    website:[],
    license:[],
    saldoTopup:number,
    updateUser:(payload:any)=>void,
    updateWebsite:(payload:any)=>void,
    updateLicense:(payload:any)=>void,
    updateSaldoTopup:(payload:number)=>void

}

export const usePortalStore = create<TPortalStore>((set)=>({
    user:{},
    website:[],
    license:[],
    saldoTopup:0,
    updateUser:(payload:any) => set({ user: payload }),
    updateWebsite:(payload:any) => set({ website: payload }),
    updateLicense:(payload:any) => set({license: payload}),
    updateSaldoTopup:(payload:number)=> set({saldoTopup:payload})
}))

// type TTopupModal = {
//     show:boolean,
//     nominal:number,
//     updateShow:(payload:boolean)=>void,
//     updateNominal:(payload:number)=>void

// }

// export const useTopupModal = create<TTopupModal>((set)=>({
//     show:false,
//     nominal:0,
//     updateShow:(payload:boolean) => set({show:payload}),
//     updateNominal:(payload:number) => set({nominal:payload}),
        
//     }))   



export function isEmpty(obj:any)
{
	return (Object.keys(obj).length === 0 && obj.constructor === Object)
}

