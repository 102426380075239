import { createBrowserRouter } from "react-router-dom"
import ErrorPage from "../pages/error"
import Settings from "../pages/settings"
import Login from "../pages/login"
import App from "../App"
import ProtectedRoute from "../components/protectedRoute"
import Licenses from "../pages/licenses"
import PageError from "../pages/error/page404"
import Message from "../pages/messages"
import Wallet from "../pages/wallets"


export default createBrowserRouter([
  {
    path: "/",
    element:<ProtectedRoute><App /></ProtectedRoute>,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "setting",
        element: <ProtectedRoute><Settings /> </ProtectedRoute>,
        errorElement: <ErrorPage />,
      },
      {
        path: "license",
        errorElement: <ErrorPage />,
        element: <ProtectedRoute><Licenses /></ProtectedRoute>
      },
      {
        path: "message",
        errorElement: <ErrorPage />,
        element: <Message />
      },
      {
        path: "wallet",
        errorElement: <ErrorPage />,
        element: <Wallet />

      }
    ]
  },
  {
    path: "/login",
    element: <Login />,
    errorElement: <ErrorPage />,
  },
  {
    path:"*",
    element: <PageError />
  }
  
  




])