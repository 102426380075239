import WalletsTable from "../components/table/table-wallets"
import Topup from "../components/modal/topup"

import { useState, useEffect } from "react"
import { formatCurrency } from "../database/query"
import { usePortalStore } from "../store/portal"


export default function Wallet() {
  const [showTopup,setShowTopup] = useState<boolean>(false)
  const [nominal,setNominal] = useState<number>(0)
  const saldoTopups = usePortalStore((state)=>state.saldoTopup) 

  const handleTopUpNominal = (type: string) => {
    if (type === '100') {
        setNominal(100000)
        setShowTopup(true)

    }
      if (type === '250') {
        setNominal(250000)
        setShowTopup(true)
    }
     if (type === '500') {
        setNominal(500000)
        setShowTopup(true)
    }



  }

  const handleTopup = ()=>{

    setShowTopup(false)
  }

  useEffect(()=>{
    
    fetch('/hello').then((res)=>{console.log(res.json())})
      
    
    


    
  },[])

  return (
    <><section className=" py-3 sm:py-5">





      <div className="px-4 mx-auto max-w-screen-2xl lg:px-12 ">
        <div className="relative overflow-hidden bg-white shadow-md dark:bg-[#292929] p-4 sm:rounded-lg">

          <div className="flex mb-4 justify-between">
            <div className="flex gap-2">
              <img src={'/images/credits.svg'}  />
              <div className="text-[#DDDDDD]">Credit</div>
            </div>
            <div className="flex gap-2">
              <img src={'/images/money.svg'} />
              <div className="text-[#DDDDDD]">{formatCurrency(saldoTopups)}</div>
            </div>
          </div>

          <div className="flex gap-4 mb-4 items-center">
            <div className="text-white mr-4">Topup</div>
            <button type="button" onClick={() => handleTopUpNominal('100')} className="bg-[#595959] font-medium text-[#DDDDDD] py-2 px-8 text-sm rounded-lg hover:bg-[#771d1d]">IDR 100.000</button>
            <button type="button" onClick={() => handleTopUpNominal('250')} className="bg-[#595959] font-medium text-[#DDDDDD] py-2 px-8 text-sm rounded-lg  hover:bg-[#771d1d]">IDR 250.000</button>
            <button type="button" onClick={() => handleTopUpNominal('500')} className="bg-[#595959] font-medium text-[#DDDDDD] py-2 px-8 text-sm rounded-lg  hover:bg-[#771d1d]">IDR 500.000</button>
          </div>

          <WalletsTable />

        </div>
        <p className="text-white-gray mt-4">*Baca syarat dan ketentuan seputar saldo LokusWP disini</p>

      </div>


    </section>

      <Topup show={showTopup} nominal={nominal} handleTopup={handleTopup} />
    </>
  )
}